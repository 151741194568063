import React, { useEffect, useState } from 'react';

import * as S from './styles';
import CardData from './CardData';
import PersonalData from './PersonalData';
import PurchaseDetails from './PurchaseDetails';
import { getLastUserInfo, maskCPF } from 'js/library/utils/helpers';
import createLead from 'js/library/utils/API/payment/apiCreateLead';
import submitLeadPayment from 'js/library/utils/API/payment/apiSubmitLeadPayment';
import checkProduct from 'js/library/utils/API/payment/apiCheckProduct';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { CircularProgress } from '@mui/material';

export default function PaymentPage(props) {
  const [currentComponent, setCurrentComponent] = useState('purchaseDetails');
  const [productData, setProductData] = useState({
    productType: '',
    name: '',
    price: '',
    description: '',
    productImage: { src: '', alt: '' },
    id: null,
    quantity: 0,
  });

  const userInfo = getLastUserInfo();
  let uId = null;

  let userCellPhone = null;
  let userEmail = '';
  let userCpf = '';

  if (!userInfo.triiboId.includes('@anonymous')) {
    uId = userInfo.uId;

    try {
      userCellPhone = userInfo.contactList.find((i) => i.type === 'cellPhone').value;
    } catch {}
    try {
      userEmail = userInfo.contactList.find((i) => i.type === 'email').value;
    } catch {}

    try {
      userCpf = maskCPF(userInfo.documentList.find((i) => i.type === 'cpf').value);
    } catch {}
  }

  const [leadInfos, setLeadInfos] = useState(null);
  const [data, setData] = useState({
    cardData: {
      holder: '',
      brand: '',
      cardNumber: '',
      expirationDate: '',
    },
    addressData: {
      address_1: '',
      address_2: '',
      city: '',
      state: '',
      postcode: '',
      country: 'Brasil',
      neighborhood: '',
      number: '',
    },
    firstName: '',
    lastName: '',
    fone: userCellPhone,
    cpf: userCpf,
    email: userEmail,
    confirm_email: userEmail,
  });
  const [loading, setLoading] = useState(false);
  const [loadingInfos, setLoadingInfos] = useState(true);

  const navigate = useNavigate();

  const ConditionalRendering = () => {
    const renderComponent = {
      purchaseDetails: (
        <PurchaseDetails productData={productData} handleLoggedCheckout={handleLoggedCheckout} />
      ),
      cardData: (
        <CardData
          setData={setData}
          data={data}
          setCurrentComponent={setCurrentComponent}
          handleSubmit={handleSubmit}
          loading={loading}
          productData={productData}
        />
      ),
      personalData: (
        <PersonalData
          setData={setData}
          data={data}
          setCurrentComponent={setCurrentComponent}
          productData={productData}
          setLeadInfos={setLeadInfos}
        />
      ),
    };

    return renderComponent[currentComponent];
  };

  const handleLoggedCheckout = () => {
    try {
      if (userInfo.triiboId.includes('@anonymous')) {
        props.setOpenState(true);
      } else {
        setCurrentComponent('personalData');
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    let lead = leadInfos;

    if (!lead) {
      const firstName = data.firstName;
      const lastName = data.lastName;

      let obj = {
        uId,
        firstName,
        lastName,
        cpf: data.cpf,
        fone: data.fone,
        email: data.email,
        productType: productData.productType,
        productId: productData.id,
        quantity: productData.quantity,
      };

      if (productData.productType === 'physical') {
        obj = {
          ...obj,
          address_1: data.addressData.address_1,
          address_2: data.addressData.address_2,
          city: data.addressData.city,
          state: data.addressData.state,
          postcode: data.addressData.postcode,
          country: data.addressData.country,
          neighborhood: data.addressData.neighborhood,
          number: data.addressData.number,
        };
      }

      try {
        const leadData = await createLead(obj);
        lead = leadData.response.lead;
      } catch (error) {
        console.error('Ocorreu um erro. Tente novamente mais tarde.');
      }
    }

    try {
      if (lead.id) {
        const expirationDateMonth = data.cardData.expirationDate.split('/')[0];
        const expirationDateYear = '20' + data.cardData.expirationDate.split('/')[1];

        const expirationDate = expirationDateMonth + '/' + expirationDateYear;

        const submitPayment = await submitLeadPayment(
          uId,
          lead.id,
          data.cardData.holder,
          data.cardData.brand,
          data.cardData.cardNumber.replace(/\D/g, '').slice(0, 16),
          expirationDate
        );

        const paymentStatus = submitPayment.payment.status;

        localStorage.setItem('paymentStatus', paymentStatus);

        if (paymentStatus === 1 || paymentStatus === 2 || paymentStatus === 20) {
          // Se o status for "Authorized", "PaymentConfirmed" ou "Scheduled"
          setLoading(false);
          if (userInfo.triiboId.includes('@anonymous')) {
            sessionStorage.setItem('@user-payment-email', data.email);
            // setOpenModalResgisterUser(true);
          } else {
            navigate('/pagamento-confirmado');
          }
        } else {
          setLoading(false);
          if (paymentStatus === 3) {
            // Pagamento recusado pelo cartão
            toast.error(
              'O pagamento foi recusado pelo cartão. Por favor, verifique os detalhes do seu cartão e tente novamente.'
            );
          } else {
            // Outro erro, possível erro interno
            toast.error(
              'Ocorreu um erro ao processar o pagamento. Por favor, tente novamente mais tarde.'
            );
          }
        }
      }
    } catch (error) {
      console.log('error', error);
      setLoading(false);
      toast.error(
        'Ocorreu um erro ao processar o pagamento. Por favor, tente novamente mais tarde.'
      );
    }
  };

  useEffect(() => {
    sessionStorage.removeItem('@user-payment-email');
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const productId = urlParams.get('productId');

    if (!productId) {
      toast.error('Produto não identificado.');
      return;
    }

    const userInfo = getLastUserInfo();
    let uId = null;

    if (!userInfo.triiboId.includes('@anonymous')) {
      uId = userInfo.uId;
    }

    checkProduct(uId, productId)
      .then((res) => {
        let productType;

        if (res.response.type === 'subscription') {
          productType = 'subscription';
        } else if (res.response.virtual) {
          productType = 'digital';
        } else {
          productType = 'physical';
        }

        setProductData({
          name: res.response.name,
          description: res.response.description,
          price: res.response.price,
          productImage: res.response.images.length > 0 ? res.response.images[0] : '',
          productType: productType,
          id: productId,
          quantity: 1,
        });
        setLoadingInfos(false);
      })
      .catch((error) => {
        setProductData(null);
        toast.error('Não foi possivel carregar as informações do produto.');
        setLoadingInfos(false);
      });
  }, []);

  if (loadingInfos) {
    return (
      <div
        style={{
          width: '100%',
          height: '50vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress size={50} />
      </div>
    );
  }

  return (
    <div className="paper-container">
      <S.Container>{productData ? ConditionalRendering() : 'Produto não encontrado'}</S.Container>
    </div>
  );
}

