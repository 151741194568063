import { Box, CircularProgress, IconButton, Typography } from '@mui/material';
import { X } from '@phosphor-icons/react';
import { getBalance_v1 } from 'js/library/utils/API/getBalance_v1';
import { findValueInArray } from 'js/library/utils/helpers';
import React, { useEffect, useState } from 'react';
import StatementTable from './StatementTable';

export default function StatementModal({ openStatement, setOpenStatement, userInfo }) {
  const [pointsBalance, setPointsBalance] = useState(null);
  const infoSeuClube = JSON.parse(sessionStorage.getItem('seuClubeInfos'));

  useEffect(() => {
    //gravando ultimo path para retornar após login
    localStorage.setItem('lastURL', window.location.href);

    if (!userInfo.isAnonymous && openStatement) {
      getBalance_v1(
        userInfo.uId,
        userInfo.triiboId,
        findValueInArray(userInfo.documentList, 'type', 'cpf').value
      )
        .then((balanceReceived) => {
          if (balanceReceived !== null) {
            setPointsBalance(balanceReceived);
          } else setPointsBalance('error');
        })
        .catch(() => setPointsBalance('error'));
    }
  }, [openStatement, userInfo]);

  const style = {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: {
      xs: '100%',
      sm: '80%',
    },
    height: {
      xs: '100%',
      sm: '50%',
    },
    maxWidth: '1000px',
    minHeight: '300px',
    bgcolor: 'background.paper',
    boxShadow: 24,
  };

  return (
    <Box sx={style}>
      <span
        style={{
          background: infoSeuClube.colorPrimary,
          color: 'white',
          display: 'flex',
          padding: 10,
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography variant="h6">Extrato de pontos</Typography>
        <IconButton onClick={() => setOpenStatement(false)}>
          <X color="#ffffff" weight="bold" />
        </IconButton>
      </span>
      <div
        style={{
          padding: 10,
          overflowY: 'scroll',
        }}
      >
        {pointsBalance === null && (
          <Box
            sx={{
              textAlign: 'center',
            }}
          >
            <CircularProgress />
          </Box>
        )}

        {pointsBalance !== null && Object.keys(pointsBalance).length > 0 && (
          <StatementTable pointsBalance={pointsBalance} />
        )}
      </div>
    </Box>
  );
}
