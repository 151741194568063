import React, { useContext, useState } from 'react';

import * as S from '../styles';
import Input from 'js/components/DesignSystem/Input';
import { SignUpContext } from 'js/context/SignUpContext';
import { validateCode } from 'js/library/utils/API/validateSMS';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import { authCode_v1 } from 'js/library/utils/API/authCode_v1';
import { ContainerSendCode, RadioButton } from '../../phoneInputStyle';
import { colorText, trackEventMatomo } from 'js/library/utils/helpers';

export default function ValidateSMS() {
  const [codeSms, setCodeSms] = useState('');
  const [loading, setLoading] = useState(false);
  const [resendSms, setResendSms] = useState(false);
  const [loadingResendSms, setLoadingResendSms] = useState(false);

  const {
    cellPhone,
    setAuthSmsInfos,
    authSmsInfos,
    validateInfos,
    signUp,
    signIn,
    pathRedirect,
    sendCodeType,
    setSendCodeType,
  } = useContext(SignUpContext);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const seuClubePersist = JSON.parse(sessionStorage.getItem('seuClubeInfos'));

  const handleValidateSMS = (e) => {
    e.preventDefault();
    setLoading(true);
    validateCode(codeSms, authSmsInfos.cellPhone, authSmsInfos.transactionId)
      .then((response) => {
        if (response.success) {
          return validateInfos.user ? signIn(validateInfos) : signUp();
        } else {
          setLoading(false);
          return toast.error(t('errors.error_ocurred'));
        }
      })
      .catch((err) => {
        if (err.response.status === 422) {
          setLoading(false);
          return toast.warning(t('errors.invalid_code'));
        } else {
          setLoading(false);
          return toast.error(t('errors.error_ocurred'));
        }
      });
  };

  const handleGetNewCode = (sendCodeTypeOptions) => {
    setCodeSms('');
    setLoadingResendSms(true);
    setSendCodeType(sendCodeTypeOptions);
    authCode_v1(null, cellPhone, sendCodeTypeOptions, pathRedirect).then((res) => {
      if (res.error) {
        return toast.error(t('errors.try_later'));
      }
      toast.success('Código enviado.');
      setAuthSmsInfos(res);
      setResendSms(false);
      setLoadingResendSms(false);
    });
  };

  if (pathRedirect) {
    window.location.top = pathRedirect;
    navigate(pathRedirect);
  }

  return (
    <S.Form onSubmit={handleValidateSMS}>
      <Input
        type="tel"
        label={
          sendCodeType.sms
            ? `${t('general.sms_code_sent_to_mobile')} ${cellPhone}`
            : `${t('general.wpp_code_sent_to_mobile')} ${cellPhone}`
        }
        value={codeSms}
        handleChange={(e) => setCodeSms(e.target.value)}
        required={true}
        style={{ textAlign: 'center' }}
        maxLength="6"
        autoFocus={true}
      />
      <p>
        {t('general.didnt_receive_the_code')}
        <S.Link onClick={() => setResendSms(true)}> {t('general.resend_sms')}</S.Link>
      </p>

      {resendSms && (
        <ContainerSendCode>
          <span>{t('general.botton-option-send-code')}</span>
          <span>
            <RadioButton
              type="button"
              $activecolor="primary"
              $isactive={sendCodeType.sms === true}
              disabled={loadingResendSms}
              onClick={() =>
                handleGetNewCode({
                  ...sendCodeType,
                  sms: true,
                  whatsapp: false,
                })
              }
            >
              SMS
            </RadioButton>
            <RadioButton
              type="button"
              $activecolor="primary"
              $isactive={sendCodeType.whatsapp === true}
              disabled={loadingResendSms}
              onClick={() =>
                handleGetNewCode({
                  ...sendCodeType,
                  sms: false,
                  whatsapp: true,
                })
              }
            >
              Whatsapp
            </RadioButton>
          </span>
        </ContainerSendCode>
      )}
      <S.Button
        onClick={() => trackEventMatomo('/validarCelular', 'tocar', 'button', 'Confirmar')}
        type="submit"
        bgColor={seuClubePersist.colorPrimary}
        style={{ color: colorText(seuClubePersist.colorPrimary) }}
        disabled={loading || codeSms.length < 6}
      >
        {loading ? (
          <CircularProgress
            size={20}
            style={{ margin: '0 auto', color: colorText(seuClubePersist.colorPrimary) }}
          />
        ) : (
          'Confirmar'
        )}
      </S.Button>
    </S.Form>
  );
}

