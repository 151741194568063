import React from 'react';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

import * as S from './styles';

export default function PaymentConfirmed() {
  return (
    <div className="paper-container">
      <S.Container>
        <CheckCircleOutlineIcon
          sx={{
            fontSize: 80,
            color: '#2ce068',
          }}
        />

        <h2>Pagamento confirmado.</h2>
      </S.Container>
    </div>
  );
}

