import { styled } from 'styled-components';

export const ObsCreditCard = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  margin-bottom: 0.5rem;

  > span {
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 600;
    color: #343434;
  }
`;
