import React, { useEffect } from 'react';
import { Buttons, DataContainer, Title } from '../styles';
import * as S from './styles';
import { Button } from '@mui/material';

export default function PurchaseDetails({ productData, handleLoggedCheckout }) {
  useEffect(() => {
    const handleUnload = () => {
      localStorage.removeItem('cupom');
    };

    window.addEventListener('unload', handleUnload);

    return () => {
      window.removeEventListener('unload', handleUnload);
    };
  }, []);

  return (
    <DataContainer>
      <Title>Detalhes da compra</Title>

      <S.ProductImage src={productData.productImage.src} alt={productData.productImage.alt} />

      <S.ProductTitle>{productData.name}</S.ProductTitle>

      <span>
        {Number(productData.price).toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        })}
      </span>

      <S.ProductDescription dangerouslySetInnerHTML={{ __html: productData.description }} />

      <Buttons>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          size="large"
          onClick={(e) => handleLoggedCheckout()}
        >
          Prosseguir para pagamento
        </Button>
      </Buttons>
    </DataContainer>
  );
}

