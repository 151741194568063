import { styled } from 'styled-components';

export const Coupon = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 0.5rem;

  @media (max-width: 600px) {
    flex-direction: column;

    > button {
      margin-bottom: 2rem;
    }
  }
`;
