import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  gap: 1rem;

  @media (max-width: 750px) {
    padding: 1rem;
  }
`;

export const Title = styled.h2`
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 18px;
  font-weight: bold;
  border-bottom: 1px solid #000;
  width: 100%;
  line-height: 45px;
  color: #343434;
`;

export const DataContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  border-radius: 10px;
  width: 100%;

  @media (max-width: 600px) {
    padding: 1rem;
  }
`;

export const DetailItem = styled.div`
  width: 100%;

  p {
    margin: 0;
    padding: 0;
  }

  span {
    font-weight: bold;
    margin-right: 5px;
  }

  @media (max-width: 600px) {
    padding-left: 1rem;
  }
`;

export const Buttons = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  margin-top: 20px;

  @media (max-width: 600px) {
    flex-direction: column;

    > button {
      width: 100%;
    }
  }
`;
